var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"warn"},[_c('a-card',[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.$store.state.chatState.chat.config.warn,
          'key': 'enabled',
          'hasAnchor': true,
        }
      }}}),(_vm.isEnabledFilterBlockVisible)?[_c('common-rules-settings',{attrs:{"custom-translation":"","filter-type":"warn","store-path":"$store.state.chatState.chat.config","pick-fields":[
          _vm.CommonRulesFields.Remove,
          _vm.CommonRulesFields.Remove_timeout,
          _vm.CommonRulesFields.Notice_before_ban_count,
          _vm.CommonRulesFields.Warning_message,
          _vm.CommonRulesFields.Ban_type,
          _vm.CommonRulesFields.Ban_notice,
          _vm.CommonRulesFields.Ban_message ],"editor-props":( _obj = {}, _obj[_vm.CommonRulesFields.Warning_message] = { placeholders: _vm.WARN_SYSTEM_PLACEHOLDERS }, _obj[_vm.CommonRulesFields.Ban_message] = { placeholders: _vm.WARN_SYSTEM_PLACEHOLDERS }, _obj )}}),_c('common-settings',{staticClass:"p-4",attrs:{"custom-translation":"","filter-type":"warn","store-path":"$store.state.chatState.chat.config","pick-fields":[
          _vm.CommonSettingFields.Take_away_xp,
          _vm.CommonSettingFields.Take_away_reputation,
          _vm.CommonSettingFields.Filter_from_to ]}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }