

















































import CommonRulesSettings from '@/views/chat/filters/components/SettingGroups/CommonRulesSettings.vue'
import VirtualNewSettings from '@/views/chat/filters/components/Fields/VirtualNewSettings.vue'
import CommonSettings from '@/views/chat/filters/components/SettingGroups/CommonSettings.vue'
import FilterLevels from '@/views/chat/filters/components/FilterLevels.vue'
import { ExtraSettingsMenuItems } from '@/views/chat/filters/FilterTypes'
import { CommonRulesFields } from '@/views/chat/filters/FilterTypes'
import { InputSetups } from '@/mixins/input-setups'
import StrictMode from '@/views/chat/filters/components/Fields/StrictMode.vue'
import HandleModified from '@/views/chat/filters/components/Fields/HandleModified.vue'
import IgnoreCaption from '@/views/chat/filters/components/Fields/IgnoreCaption.vue'
import HandleUserBio from '@/views/chat/filters/components/Fields/HandleUserBio.vue'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import { CommonSettingFields } from '@/views/chat/filters/FilterTypes'
import FilterViewMixin from '@/views/chat/filters/include/FilterViewMixin'
import Placeholders from '@/mixins/placeholders/placeholders'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    HandleUserBio,
    IgnoreCaption,
    HandleModified,
    StrictMode,
    CommonRulesSettings,
    VirtualNewSettings,
    CommonSettings,
    FilterLevels,
  },
  data() {
    return {
      ExtraSettingsMenuItems,
      VirtualNewFields,
      CommonSettingFields,
      CommonRulesFields,
    }
  }
})
export default class WarnView extends Mixins<UseFields, InputSetups, Placeholders>(UseFields, InputSetups, Placeholders) {
  get isEnabledFilterBlockVisible(): boolean {
    return this.$store.state.chatState.chat.config.warn.enabled
  }
}
